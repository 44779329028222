import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Nevigate from "../components/Nevigate";
import NavDrawer from "../components/NavDrawer";
import Footerapp from "../components/Footer";
import SwitchRole from "../components/SwitchRole";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../pages/index.css";
import "antd/dist/antd.css";

import api from "../util/api";

import Target from "../../images/navicons/target.svg";
import Property from "../../images/navicons/property.svg";
import MapIcon1 from "../../images/navicons/map.svg";

import isee_og from "../../images_webp/index-introduce-1.webp";
import isee_logo from "../../images/logo/iseelogosmall.png";
import Icon_target1 from "../../images/navicons/1.1 target-eef.svg";
import Icon_target2 from "../../images/navicons/1.2 target-help.svg";
import Icon_fund1 from "../../images/navicons/2.1 cct.svg";
import Icon_fund2 from "../../images/navicons/2.2 vec.svg";
import Icon_fund3 from "../../images/navicons/2.3 krt.svg";
import Icon_fund4 from "../../images/navicons/2.4 tps.svg";
import Icon_fund5 from "../../images/navicons/2.5 target group.svg";
import Icon_inov1 from "../../images/navicons/3.2 abe.svg";
import Icon_inov2 from "../../images/navicons/4.1 tsqp.svg";
import Icon_research1 from "../../images/navicons/4.1 FSQL.svg";
import Icon_research2 from "../../images/navicons/4.2 NEA.svg";
import Icon_research3 from "../../images/navicons/4.3 pisa.svg";
import Icon_sit from "../../images/navicons/5.1 situation.svg";
import Icon_home from "../../images/navicons/home.svg";
import Icon_ref from "../../images/navicons/ref.svg";
import T from "../util/translate/translate";
import Swal from "sweetalert2";
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const main_menu = [
  {
    id: "index",
    name: "หน้าหลัก",
    nameEN: "Home",
    roles: ["public", "public-login", "partner", "eef", "representative"],
  },
  {
    id: "target",
    name: "กลุ่มเป้าหมาย กสศ.",
    nameEN: "EFF’s Target Groups",
    roles: ["public", "public-login", "partner", "eef"],
    url: "/target",
  },
  {
    id: "sub_menu_fund",
    name: "ทุนสร้างโอกาส",
    nameEN: "Opportunity Scholarships",
    roles: ["public", "public-login", "partner", "eef"],
  },
  {
    id: "sub_menu_innovation",
    name: "นวัตกรรมต้นแบบ",
    nameEN: "Model Innovation",
    roles: ["public-login", "partner", "eef"],
  },
  {
    id: "sub_menu_research",
    name: "งานวิจัย",
    nameEN: "Research",
    roles: ["public-login", "partner", "eef"],
  },
  {
    id: "sub_menu_database",
    nameEN: "Database",
    name: "ฐานข้อมูล กสศ.",
    roles: ["partner", "eef"],
  },
  {
    id: "area_election",
    name: "ภาพรวมระดับประเทศ",
    roles: ["representative"],
    url: "/area-election",
  },
  {
    id: "map_election",
    name: "การดำเนินงานในพื่นที่ของท่าน",
    roles: ["representative"],
    url: "/map-election",
  },
  {
    id: "eef_oosc",
    name: "เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา",
    nameEN: "OUT OF SCHOOL CHILDREN",
    roles: ["public-login"],
    url: "/fullmap-eef-oosc",
  },
  {
    id: "sub_menu_contect",
    name: "ติดต่อ กสศ.",
    nameEN: "Contact",
    roles: ["public", "public-login", "partner", "representative"],
    url: "/contact",
  },

  {
    id: "province_index",
    name: "หน้าหลัก",
    roles: ["partner_province"],
    url: "/province",
  },
  {
    id: "province_student",
    name: "สถานการณ์ด้านการศึกษา",
    roles: ["partner_province"],
    // url: '/province/student-statistics',
  },
  {
    id: "province_eef",
    name: "กสศ.ในพื้นที่ของท่าน",
    roles: ["partner_province"],
    url: "/province/fund_province",
  },
  {
    id: "province_mapschool",
    name: "แผนที่โรงเรียน",
    roles: ["partner_province"],
    url: "/school",
  },
  {
    id: "province_eefcompare",
    name: "เปรียบเทียบ",
    roles: ["partner_province"],
    url: "/province/eef-compare",
  },
];

const sub_menu = {
  index: [
    {
      name: "หน้าหลัก",
      nameEN: "MAIN",
      content: [],
      url: "/",
      roles: ["public", "public-login", "partner", "eef", "representative"],
      icon: Icon_home,
    },
    {
      name: "แหล่งที่มาข้อมูล.",
      nameEN: "References",
      content: [],
      url: "/ref-all",
      roles: ["public", "public-login", "partner", "eef", "representative"],
      icon: Icon_ref,
    },
  ],
  sub_menu_target: [
    {
      name: "กลุ่มเป้าหมาย กสศ.",
      nameEN: "EEF’s Target Groups",
      content: [],
      url: "/target_highlight",
      roles: ["public", "public-login", "partner", "eef"],
      icon: Icon_target1,
    },
    {
      name: "การช่วยเหลือกลุ่มเป้าหมาย กสศ.",
      nameEN: "Assistance to target group EEF",
      content: [],
      url: "/target",
      roles: ["public", "public-login", "partner", "eef"],
      icon: Icon_target2,
    },
  ],
  sub_menu_fund: [
    {
      name: "ทุนเสมอภาค",
      nameEN: "Conditional Cash Transfer",
      content: [
        {
          text: "ภาพรวมทุนเสมอภาค",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/cct",
        },
        {
          text: "สำรวจทุนเสมอภาค",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-cct",
        },
        {
          text: "ข้อมูลครัวเรือนนักเรียนยากจน/ยากจนพิเศษ",
          textEN: "",
          roles: ["eef"],
          link: "/dashboard-pmt",
        },
      ],
      url: "/cct",
      roles: ["public", "public-login", "partner", "eef"],
      icon: Icon_fund1,
    },
    {
      name: "ทุนนวัตกรรมสายอาชีพชั้นสูง",
      nameEN: "High Vocational Innovation Scholarships",
      content: [
        {
          text: "ภาพรวมทุนนวัตกรรมสายอาชีพชั้นสูง",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/vec",
        },
        {
          text: "สำรวจทุนนวัตกรรมสายอาชีพชั้นสูง",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-vec",
        },
      ],
      url: "/vec",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_fund2,
    },
    {
      name: "ทุนครูรัก(ษ์)ถิ่น",
      nameEN: "Homegrown Teacher Scholarships",
      content: [
        {
          text: "ภาพรวมทุนครูรัก(ษ์)ถิ่น",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/krt",
        },
        {
          text: "สำรวจทุนครูรัก(ษ์)ถิ่น",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-krt",
        },
      ],
      url: "/krt",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_fund3,
    },
    {
      name: "ทุนพระกนิษฐาสัมมาชีพ",
      nameEN: "Scholarships for Vocational Education from Her Royal Highness Princess Maha Chakri Sirindhorn",
      content: [
        {
          text: "ภาพรวมทุนพระกนิษฐาสัมมาชีพ",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/tps",
        },
        {
          text: "สำรวจทุนพระกนิษฐาสัมมาชีพ",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-tps",
        },
      ],
      url: "/tps",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_fund4,
    },
    {
      name: "ทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน ",
      nameEN: "Community-Based Innovation and Career Development Scholarships",
      content: [
        {
          text: "ภาพรวมทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/target_group",
        },
        {
          text: "สำรวจทุนพัฒนาอาชีพและนวัตกรรมที่ใช้ชุมชนเป็นฐาน",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-targetgroup",
        },
      ],
      url: "/target_group",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_fund5,
    },
    {
      nameEN: "please login",
      name: "ทุนอื่นๆ กรุณา Login ",
      content: [],
      url: "/login",
      roles: ["public"],
      icon: null,
    },
  ],
  sub_menu_innovation: [
    {
      name: "TSQP",
      nameEN: "TSQP",
      content: [
        {
          text: "ภาพรวมโครงการTSQP",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/tsqp",
        },
        {
          text: "สำรวจ โครงการTSQP",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-tsqp",
        },
      ],
      url: "/tsqp",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_inov2,
    },
    {
      name: "การจัดการศึกษาเชิงพื้นที่",
      nameEN: "Area-based Education Projects",
      content: [
        {
          text: "เด็กปฐมวัยยากจนใน ศพด.",
          textEN: "Underprivileged preschool children in early childhood development centers ",
          roles: ["public-login", "partner", "eef"],
          link: "/ecd",
        },
        {
          text: "แผนที่เด็กปฐมวัยยากจนใน ศพด.",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-ecd",
        },
        {
          textEN: "Out-of-school children",
          text: "เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา",
          roles: ["public-login", "partner", "eef"],
          link: "/oosc",
        },
        {
          text: "แผนที่เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-abe-oosc",
        },
      ],
      url: "/ecd",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_inov1,
    },
  ],
  sub_menu_research: [
    {
      name: "FSQL",
      nameEN: "FSQL",
      content: [
        {
          text: "ภาพรวม FSQL",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fsql",
        },
        {
          text: "สำรวจ FSQL",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/fullmap-fsql",
        },
      ],
      url: "/fsql",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_research1,
    },
    {
      name: "NEA",
      nameEN: "NEA",
      content: [
        {
          text: "ภาพรวมNEA",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/nea",
        },
        {
          text: "สำรวจNEA",
          textEN: "",
          roles: ["partner", "eef"],
          link: "/nea_detail",
        },
      ],
      url: "/nea",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_research2,
    },
    {
      name: "PISA for School",
      nameEN: "PISA for School",
      content: [],
      url: "/pisa",
      roles: ["public-login", "partner", "eef"],
      icon: Icon_research3,
    },
  ],
  sub_menu_database: [
    {
      name: "สถานการณ์ด้านการศึกษา",
      nameEN: "สถานการณ์ด้านการศึกษา",
      inOrganization: true,
      content: [
        {
          text: "ประชากรวัยเรียน",
          textEN: "",
          roles: ["eef"],
          link: "/student-statistics/country",
        },
        {
          text: "นักเรียนด้อยโอกาส/พิการ",
          textEN: "",
          roles: ["eef"],
          link: "/disadvantaged-disabled/country",
        },
        {
          text: "ทรัพยากรโรงเรียน",
          textEN: "",
          roles: ["eef"],
          link: "/target-resources/country",
        },
        {
          text: "เด็กที่ไม่มีข้อมูลในระบบการศึกษา",
          textEN: "",
          roles: ["eef"],
          link: "/fullmap-eef-oosc",
        },
      ],
      url: "/student-statistics/country",
      roles: ["eef"],
      icon: Icon_sit,
    },
    {
      name: "การดำเนินงานของ กสศ. ในพื้นที่ของท่าน",
      nameEN: "การดำเนินงานของ กสศ. ในพื้นที่ของท่าน",
      content: [],
      url: "/fund",
      roles: ["partner", "eef"],
      icon: Target,
    },
    {
      name: "ค้นหาสถานศึกษา",
      nameEN: "ค้นหาสถานศึกษา",
      content: [],
      url: "/school",
      roles: ["partner", "eef"],
      icon: MapIcon1,
    },
    {
      name: "ระบบบริหารจัดการโครงการ (PMS)",
      nameEN: "ระบบบริหารจัดการโครงการ (PMS)",
      inOrganization: true,
      content: [
        {
          text: "ภาพรวมโครงการ กสศ.",
          textEN: "",
          roles: ["eef"],
          link: "/pms-overview",
        },
        {
          text: "จำนวนพื้นที่ดำเนินงานทั้งหมดของ กสศ.",
          textEN: "",
          roles: ["eef"],
          link: "/pms-area-process",
        },
        {
          text: "จำนวนโครงการจำแนกตามกลุ่มเป้าหมาย",
          textEN: "",
          roles: ["eef"],
          link: "/pms-target-group",
        },
      ],
      url: "/pms-overview",
      roles: ["eef"],
      icon: Property,
    },
    {
      name: "Dashboard กสศ.",
      inOrganization: true,
      content: [
        {
          text: "สรุปการดำเนินงานภาพรวมของ กสศ. (EEF Dashboard)",
          textEN: "",
          roles: ["eef"],
          link: "/eef-dashboard",
        },
        {
          text: "เปรียบเทียบข้อมูลการช่วยเหลือผ่านทุนของ กสศ.",
          textEN: "",
          roles: ["eef"],
          link: "/eef-compare",
        },
        {
          text: "ข้อมูลการวิเคราะความสัมพันธ์จากฐานข้อมูลทุนเสมอภาค",
          textEN: "",
          roles: ["eef"],
          link: "/dashboard-analytics",
        },
      ],
      url: "/eef-dashboard",
      roles: ["eef"],
      icon: Icon_research2,
    },
  ],
  sub_menu_contect: [
    {
      name: "ติดต่อ กสศ.",
      nameEN: "contact",
      content: [],
      url: "/contact",
      roles: ["public", "public-login", "partner", "eef"],
      icon: Icon_sit,
    },
  ],
  sub_menu_election: [
    {
      name: "สส.",
      content: [
        {
          text: "ภาพรวมระดับประเทศ",
          textEN: "",
          roles: ["eef"],
          link: "/area-election",
        },
        {
          text: "การดำเนินงานในพื่นที่ของท่าน",
          textEN: "",
          roles: ["eef"],
          link: "/map-election",
        },
      ],
      url: "/area-election",
      roles: ["eef"],
      icon: Icon_sit,
    },
  ],
  province_student: [
    {
      name: "ประชากรวัยเรียน",
      content: [],
      url: "/province/student-statistics",
      roles: ["partner_province"],
      // icon: Icon_sit
    },
    {
      name: "นักเรียนด้อยโอกาส/พิการ",
      content: [],
      url: "/province/disadvantaged-disabled",
      roles: ["partner_province"],
      // icon: Icon_sit
    },
    {
      name: "โรงเรียน",
      content: [],
      url: "/province/school",
      roles: ["partner_province"],
      // icon: Icon_sit
    },
    {
      name: "ทรัพยากร",
      content: [],
      url: "/province/school_resources",
      roles: ["partner_province"],
      // icon: Icon_sit
    },
  ],
};

// TODO: add isLoading screen
export default function Layout({
  isLoading,
  children,
  isHideFooter = false,
  backgroundImage = null,
  Role = null,
  backgroundColor = "#E5E7EB",
  getUserRoles = null,
  getLang = null,
}) {
  const [state, setState] = React.useState({
    top: false,
  });
  const [isLogin, setIsLogin] = React.useState(false);
  const [userData, setUserData] = React.useState(null);
  const [userRole, setUserRole] = React.useState(null);
  const [mainMenu, setMainMenu] = React.useState([]);
  const [subMenu, setSubMenu] = React.useState([]);
  const [showChildren, setShowChildren] = React.useState(false);
  const [lang, setLang] = React.useState(null);
  const [translate, setTranslate] = React.useState(null);
  const [openChangeRole, setOpenChangeRole] = React.useState(false);
  const [roles, setRoles] = React.useState(["public-login"]);

  let theme = createTheme({
    typography: {
      fontFamily: `'Kanit', sans-serif`,
    },
    palette: {
      primary: {
        main: "#048967",
      },
      secondary: {
        main: "#E8FDF0",
      },
    },
  });

  theme.typography.h4 = {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontFamily: "Kanit",
      fontSize: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "2.3rem",
    },
  };

  theme.typography.h6 = {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontFamily: "Kanit",
      fontSize: "0.75rem",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "1.25rem",
    },
  };

  theme.typography.h7 = {
    fontSize: "1.2rem",
    [theme.breakpoints.down("md")]: {
      fontFamily: "Kanit",
      fontSize: "0.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "0.85rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontFamily: "Kanit",
      fontWight: 100,
      fontSize: "1.1rem",
    },
  };

  const [windowDimensions, setWindowDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [open_warning, setOpenWarning] = React.useState(false);
  let isCanChangeLang = (_userRoles) => {
    if (Array.isArray(_userRoles)) {
      return _userRoles.includes("public-login") || _userRoles.includes("public") || _userRoles.length === 0;
    } else {
      return userRole == "public-login" || userRole === null || userRole === "public";
    }
  };

  React.useEffect(async () => {
    setWindowDimensions(getWindowDimensions());
    let is_login = api.isLogin().status;
    let user_data = await (is_login ? api.getUserInfo() : null);
    let userrole = user_data ? [user_data.role_active] : ["public"];
    userrole = userrole.length === 0 && is_login ? ["public-login"] : userrole;
    user_data = user_data ? user_data : { user: null, name: null, roles: userrole };

    let active_role = api.getActiveRole();
    if (is_login && active_role !== "public-login") {
      if (user_data.roles) {
        if (!user_data.roles.includes(active_role)) {
          Swal.fire("แจ้งเตือน", `คุณไม่สามารถเข้าถึงข้อมูลนี้ได้`, "warning").then((result) => {
            api.onLogout();
          });
        }
      }
    }

    setRoles(user_data.roles.length > 0 ? user_data.roles : ["public"]);
    setUserData(user_data);
    setUserRole(userrole);
    if (is_login) {
      setIsLogin(true);
      if (window.location.pathname === "/") {
        if (userrole.includes("partner_province")) {
          navigate(`/province`);
        } else {
          navigate(`/`);
        }
      }
    }

    let allow_mainMenu = [];
    main_menu.map((item) => {
      let allow = api.rolePermission(userrole.includes("admin") ? ["eef"] : userrole, item.roles);
      if (allow) {
        allow_mainMenu.push(item);
      }
    });

    let allow_subMenu = {};
    let sub = JSON.parse(JSON.stringify(sub_menu));
    for (let i in sub) {
      allow_subMenu[i] = [];
      sub[i].forEach((item) => {
        let allow_head = api.rolePermission(userrole.includes("admin") ? ["eef"] : userrole, item.roles);
        if (allow_head) {
          if (item.content.length > 0) {
            let arr = [];
            item.content.forEach((con) => {
              let allow_sub = api.rolePermission(userrole.includes("admin") ? ["eef"] : userrole, con.roles);
              if (allow_sub) {
                arr.push(con);
              }
            });
            item.content = arr;
            allow_subMenu[i].push(item);
          } else {
            allow_subMenu[i].push(item);
          }
        }
      });
    }
    setMainMenu(allow_mainMenu);
    setSubMenu({ ...allow_subMenu });
    console.log("Role", Role);
    if (Role) {
      console.log("userrole", userrole);
      if (Role.length > 0 && !Role.includes("public-login") && !Role.includes("public")) {
        let path = window.location.pathname
          .split("/")
          .filter((item) => item !== "")
          .pop();
        if (path) api.getInitPage(path);
      }

      if (!api.rolePermission(userrole, Role)) {
        if (is_login) {
          Swal.fire("แจ้งเตือน", `คุณไม่สามารถเข้าถึงข้อมูลนี้ได้`, "warning").then((result) => {
            navigate(-1); // userRole?.includes("partner_province") ? navigate("/province") : navigate("/")
          });
        } else {
          Swal.fire("แจ้งเตือน", `กรุณาเข้าสู่ระบบเพื่อเข้าถึงข้อมูลนี้`, "warning").then((result) => {
            navigate("/login");
          });
        }
      } else {
        setShowChildren(true);
      }
    } else {
      setShowChildren(true);
    }
  }, []);

  React.useEffect(() => {
    if (getUserRoles) getUserRoles(userRole);
  }, [getUserRoles, userRole]);

  React.useEffect(async () => {
    let _lang = localStorage.getItem("lang");
    if (isCanChangeLang(userRole)) {
      setLang(_lang ? _lang : "TH");
    } else {
      setLang("TH");
    }
  }, [userRole]);

  React.useEffect(() => {
    if (getLang && lang) {
      if (lang === "EN") {
        getLang(T.EN, "EN");
        setTranslate(T.EN);
      } else {
        getLang(T.TH, "TH");
        setTranslate(T.TH);
      }
    } else if (lang) {
      setTranslate(T.TH);
    }
  }, [lang]);

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  let isScreenSizeGL = windowDimensions.width < 900;

  const [isHideMenu, setHideMenu] = React.useState(false);
  React.useEffect(() => {
    window.onscroll = () => {
      setHideMenu(window.pageYOffset > 50 ? "none" : "");
    };
    return () => (window.onscroll = null);
  });

  const Loader = () => (
    <div className="relative overflow-hidden ">
      <div className="w-[100vw] h-[100vh] flex flex-col justify-center items-center">
        <div className="text-center" role="status">
          <svg
            className="inline mr-2 w-32 h-32 text-gray-200 animate-spin dark:text-gray-200 fill-[#038967]"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
        <div className="text-2xl text-[#038967] font-bold text-center mt-4"> Loading ...</div>
      </div>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <head>
        <title>{"iSEE: Information system for Equitable Education"}</title>
        <meta name="description" content={"Data Platform เพื่อส่งเสริมความเสมอภาคทางการศึกษาของประเทศไทย"} />
        <meta name="image" content={isee_logo} />
        <meta property="og:title" content="iSEE: Information system for Equitable Education" />
        <meta property="og:description" content="Data Platform เพื่อส่งเสริมความเสมอภาคทางการศึกษาของประเทศไทย" />
        <meta property="og:image" content={isee_og} />
        <link rel="icon" href={isee_logo} />
      </head>
      {/* <Modal
        title={<span className="text-2xl font-bold">! แจ้งเตือน</span>}
        visible={open_warning}
        onCancel={() =>
          isLogin
            ? userRole.includes("partner_province")
              ? navigate("/province")
              : navigate("/")
            : navigate("/login")
        }
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[
          <Button
            href={isLogin ? "/" : "/login"}
            variant="contained"
            sx={{ backgroundColor: "3ECC4E" }}
          >
            ตกลง
          </Button>,
        ]}
      >
        <p className="text-lg">
          ไม่สามารถเข้าถึงข้อมูลได้ {!isLogin ? "กรุณาเข้าสู่ระบบ" : ""}
        </p>
      </Modal> */}
      <SwitchRole roles={roles} open={openChangeRole} onCancel={() => setOpenChangeRole(false)} />
      <NavDrawer
        lang={getLang ? lang : "TH"}
        userData={userData}
        mainMenu={mainMenu}
        subMenu={subMenu}
        open={state.top}
        isChangeRoles={roles.length > 1}
        onOpenchangRole={(open) => setOpenChangeRole(open)}
        onClose={() => {
          setState({ top: false });
        }}
      ></NavDrawer>
      <Nevigate
        translate={translate}
        userData={userData}
        mainMenu={mainMenu}
        subMenu={subMenu}
        isHideMenu={isHideMenu}
        lang={getLang ? lang : "TH"}
        isChangeRoles={roles.length > 1}
        isShowChangeLang={(getLang ? true : false) && isCanChangeLang(userRole)}
        closeD={() => setState({ top: true })}
        onOpenchangRole={(open) => setOpenChangeRole(open)}
        changeLang={(lang) => setLang(lang)}
      />
      <div
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          backgroundColor: backgroundColor,
          backgroundImage: backgroundImage,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        {/* <div id="main" className="min-h-[800px] w-full"> */}
        <div id="main" className="w-full">
          {isLoading ? <Loader /> : showChildren && children}
        </div>
      </div>
      {!isHideFooter && (
        <Footerapp
          // role={userRole ?? ['public']}
          mainMenu={mainMenu}
          subMenu={subMenu}
          isHideMenu={isHideMenu}
          isScreenSizeGL={isScreenSizeGL}
          translate={translate}
          lang={lang}
        />
      )}
    </ThemeProvider>
  );
}
